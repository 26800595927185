[data-right-sidebar="white"] {
  .right-sidebar {
    @apply bg-white text-grey-900;
    code,
    pre {
      @apply bg-grey-100;
    }
  }
}

[data-right-sidebar="grey-100"] {
  .right-sidebar {
    @apply bg-grey-100 text-grey-900;
    code,
    pre {
      @apply bg-grey-200;
    }
  }
}

[data-right-sidebar="grey-800"] {
  .right-sidebar {
    @apply bg-grey-800 text-white;
    code,
    pre {
      @apply bg-grey-700;
    }
  }
}

[data-right-sidebar="grey-900"] {
  .right-sidebar {
    @apply bg-grey-900 text-white;
    code,
    pre {
      @apply bg-grey-800;
    }
  }
}

[data-right-sidebar="indigo-800"] {
  .right-sidebar {
    @apply bg-indigo-800 text-white;
    code,
    pre {
      @apply bg-indigo-700;
    }
  }
}

[data-right-sidebar="indigo-700"] {
  .right-sidebar {
    @apply bg-indigo-700 text-white;
    code,
    pre {
      @apply bg-indigo-600;
    }
  }
}
/*
@import "../variables";

@each $key, $value in $palettes {
  [data-right-sidebar="#{$key}"] {
    .right-sidebar {
      @apply #{map-get($value, "background-color")} #{map-get($value, "text-color")};
    }
  }
}
*/

