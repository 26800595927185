.widget {
  @apply w-full p-4 rounded-lg;
  .title {
    @apply text-sm font-light;
  }
  .description {
    @apply text-sm font-bold;
  }
}

[data-background="light"] {
  .widget {
    @apply bg-white border border-grey-100;
    .title {
      @apply text-grey-500;
    }
  }
}

[data-background="dark"] {
  .widget {
    background: color(theme('colors.grey.900') tint(5%));
    border-color: color(theme('colors.grey.900') tint(10%));
    .title {
      @apply text-grey-500;
    }
  }
}
