@media (min-width: 768px) {
  .arrow {
    transform: rotate(90deg);
    transition: transform 0.15s ease-in-out;
    [data-collapsed="true"] & {
      @apply hidden;
    }
  }

  [data-collapsed="false"] {
    .hidden-sibling {
      + ul {
        @apply hidden;
      }
      &.left-sidebar-item {
        .arrow {
          transform: rotate(-90deg) !important;
        }
      }
    }
  }
  [data-collapsed="true"] {
    .hidden-sibling {
      + ul {
        @apply block;
      }
    }
  }

  .left-sidebar-1 {
    .logo {
      @apply uppercase font-bold text-base tracking-wider flex flex-row items-center justify-start h-16 w-full whitespace-no-wrap pl-10;
      max-width: calc(0.25rem * 64);
      [data-collapsed="false"] & {

      }
      [data-collapsed="true"] & {
        max-width: calc(0.25rem * 16);
        span {
          @apply hidden;
        }
      }
    }
  }

  .left-sidebar-item {
    @apply flex flex-row h-10 items-center justify-start relative whitespace-no-wrap;
    min-width: calc(0.25rem * 16);
    max-width: calc(0.25rem * 64);
    width: calc(0.25rem * 64);
    text-transform: uppercase;
    > svg {
      width: calc(0.25rem * 16);
    }
    [data-collapsed="true"] & {
      max-width: calc(0.25rem * 64);
      width: calc(0.25rem * 16);
    }
    &.open-sibling {
      @apply text-blue-500;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 4px;
        @apply bg-blue-500;
      }
    }
    &:after {
      content: "";
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      @apply bg-blue-500;
    }
    &:hover,
    &.active {
      @apply text-blue-500;
      &:after {
        display: block;
      }
    }
  }

  [data-collapsed="false"] {
    .l0 {
      > .left-sidebar-item {
        > .title {
          @apply visible opacity-100;
        }
      }
    }
  }
  [data-collapsed="true"] {
    .l0 {
      > .left-sidebar-item {
        > .title {
          @apply invisible opacity-0;
        }
      }
    }
  }

  .left-sidebar-title {
    @apply uppercase font-normal text-xs tracking-wider flex flex-row items-center justify-start h-16 w-full px-4;
    [data-collapsed="false"] & {
      @apply visible opacity-100;
    }
    [data-collapsed="true"] & {
      @apply invisible opacity-0 h-0;
    }
  }

  [data-collapsed="false"] {
    ul ul .left-sidebar-item {
      padding-left: calc(0.25rem * 16);
    }
    ul ul ul .left-sidebar-item {
      padding-left: calc(0.25rem * 20);
    }
    ul ul ul ul .left-sidebar-item {
      padding-left: calc(0.25rem * 24);
    }
    ul ul ul ul ul .left-sidebar-item {
      padding-left: calc(0.25rem * 28);
    }
  }

  .left-sidebar-1 {
    @apply text-sm shadow-lg;
    [data-left-sidebar="light"] & {
      @apply bg-white border-r border-grey-200 text-grey-600;
    }
    [data-left-sidebar="dark"] & {
      @apply border-r text-white;
      background: color(#212121 tint(5%));
      border-color: color(#212121 tint(10%));
    }
    .badge {
      @apply hidden !important;
    }
    [data-collapsed="true"][data-left-sidebar="light"] & {
      ul > li {
        &:not(.l0) {
          @apply bg-white;
        }
        > ul {
          @apply bg-white;
        }
      }
    }
    [data-collapsed="true"][data-left-sidebar="dark"] & {
      ul > li {
        &:not(.l0) {
          background: color(#212121 tint(5%));
        }
        > ul {
          background: color(#212121 tint(5%));
        }
      }
    }
    [data-collapsed="true"] & {
      ul > li {
        @apply relative;
        &.l0 {
          > .left-sidebar-item {
            > .title {
              @apply hidden;
            }
          }
        }
        &:not(.l0) {
          @apply w-48;
          > .left-sidebar-item {
            @apply flex flex-row h-10 w-48 items-center justify-start relative px-3;
          }
        }
        > ul {
          @apply absolute top-0 w-48 shadow-lg z-10 invisible opacity-0 cursor-none;
          left: 100%;
        }
        &:hover {
          > ul {
            @apply visible opacity-100 cursor-auto;
          }
        }
      }
    }
  }
}
