[data-top-navigation="white"] {
  .top-navigation {
    @apply bg-white text-grey-900;
    code,
    pre {
      @apply bg-grey-100;
    }
  }
}

[data-top-navigation="grey-100"] {
  .top-navigation {
    @apply bg-grey-100 text-grey-900;
    code,
    pre {
      @apply bg-grey-200;
    }
  }
}

[data-top-navigation="grey-800"] {
  .top-navigation {
    @apply bg-grey-800 text-white;
    code,
    pre {
      @apply bg-grey-700;
    }
  }
}

[data-top-navigation="grey-900"] {
  .top-navigation {
    @apply bg-grey-900 text-white;
    code,
    pre {
      @apply bg-grey-800;
    }
  }
}

[data-top-navigation="indigo-800"] {
  .top-navigation {
    @apply bg-indigo-800 text-white;
    code,
    pre {
      @apply bg-indigo-700;
    }
  }
}

[data-top-navigation="indigo-700"] {
  .top-navigation {
    @apply bg-indigo-700 text-white;
    code,
    pre {
      @apply bg-indigo-600;
    }
  }
}
/*
@import "../variables";
@each $key, $value in $palettes {
  [data-top-navigation="#{$key}"] {
    .top-navigation {
      @apply #{map-get($value, "background-color")} #{map-get($value, "text-color")};
      ul {
        @apply #{map-get($value, "background-color")} #{map-get($value, "text-color")};
        li {
          @apply #{map-get($value, "background-color")} #{map-get($value, "text-color")};
          a {
            &:hover {
              @apply #{map-get($value, "hover-background-color")};
            }
          }
        }
      }
    }
  }
}
*/
