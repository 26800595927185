.regions-frame {
   display:flex;
   min-height: 90%;
}

.regions-list {
   flex:1;
}

.regions-details {
   flex:3;
}




.region-table-header {
  display:flex;
  background-color:#37708e;
  color:white;
  font-size:16px;
  padding: 6px 20px;
}

.region-table-header div{
  cursor:pointer;
}
 
.region-table-id {
  flex:1;
}
 
.region-table-name {
  flex:4;
}

.region-table-cabs {
  flex:2;
  text-align: center;
}


.region-table-row{
  font-family: Arial;
  display:flex;
  background-color:white;
  color:#555;
  font-size:12px;
  padding: 6px 20px;
  border-bottom:1px solid #eee;
  cursor:pointer;
}
 
.region-table-row:hover {
  background-color: #ddd;
}


.region-detail-frame{
   display:flex;
  margin-left:20px;  
  border:1px solid #888;
  border-radius: 4px;
  height:100%;
  width:100%;
  overflow-y:scroll;
}

.region-detail-container{

 }


.region-info-container {
  flex:1;
}

.region-map-container {
  margin:5px;
  flex:1;
  height:40%;
  border:1px solid #999;
}

.region-info-name{
  font-size: 20px;
  font-weight: bold;
  padding-top:10px;
  padding-left:20px;
}