[data-navbar="light"] {
  .navbar {
    @apply text-grey-900;
    @apply bg-white border-grey-100;
  }
}

[data-navbar="dark"] {
  .navbar {
    @apply text-white;
    background: color(#212121 tint(5%));
    border-color: color(#212121 tint(10%));
    .navbar-search {
      input {
        background: color(#212121 tint(10%));
        border-color: color(#212121 tint(10%));
      }
    }
  }
}

/*
@import "../variables";

@each $key, $value in $palettes {
  [data-navbar="#{$key}"] {
    .navbar {
      @apply #{map-get($value, "background-color")};
      @apply #{map-get($value, "text-color")};
      input {
        @apply #{map-get($value, "hover-background-color")};
      }
      .navbar-dropdown {
        @apply #{map-get($value, "background-color")};
        @apply #{map-get($value, "text-color")};
        &-title {
          @apply #{map-get($value, "highlight-background-color")};
          @apply #{map-get($value, "highlight-text-color")};
        }
      }
    }
  }
}
*/
