
.myTrips-table-header {
  display:flex;
  background-color:#37708e;
  color:white;
  font-size:16px;
  padding: 6px 20px;
}

.myTrips-table-header div{
  cursor:pointer;
}

.myTrips-icon {
  flex:0.2;
  font-size:16px;
}

.myTrips-date {
  flex:1;
}

.myTrips-driver {
  flex:0.8;
}

.myTrips-region{
  flex:1;
}

.myTrips-currency {
  flex:1;
}

.myTrips-currency span{
  margin-right:3rem;
}

.myTrips-permit {
  flex:1;
}

.myTrips-booking {
  flex:0.8;
}

.myTrips-address {
  flex:2;
}

.myTrips-status {
  flex:2;
}

.myTrips-payment{
  flex:0.7;
}

.myTrips-table-row{
  font-family: Arial;
  display:flex;
  background-color:white;
  color:#555;
  font-size:12px;
  padding: 6px 20px;
  border-bottom:1px solid #eee;
  cursor:pointer;
}

.myTrips-table-row:hover {
  background-color: #ddd;
}

.myTrips-table-content{
  height: 90%;
  overflow: auto;
  padding-bottom:100px;
}

.mytrips-dialog {
  min-height:400px;
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
}

.mytrips-dialog-header{
  display:flex;
}

.mytrips-dialog-header-icon {
  padding-top:5px;
  margin-left:2px;
  flex:1;
}


.mytrips-dialog-header-booking {
  flex:10;
}

.mytrips-dialog-header-status {
  flex:10;
  text-align: right;
}

.mytrips-dialog-wrapper {
  display:flex;
  flex-direction: row;
}

@media (max-width: 800px) {
  .mytrips-dialog-wrapper {
    flex-direction: column;
  }
}


.mytrips-dialog-column {
  flex:3;
}

.mytrips-log-column {
  flex:4;
}



.myTrips-dialog-address-info {
  display:flex;
  padding:2px;
  font-size:14px;
}

.myTrips-dialog-address-info .myTrips-dialog-info-key {
  flex:1;
}

.myTrips-dialog-address-info .myTrips-dialog-info-value {
  flex:8;
}

.myTrips-dialog-info{
  display:flex;
  padding:2px;
  font-size:14px;
}
.myTrips-dialog-info .myTrips-dialog-info-key {
  flex:2;
}
.myTrips-dialog-info .myTrips-dialog-info-value {
  flex:3;
}

.myTrips-dialog-column-header {
  margin-top:20px;
  font-size:16px;
  margin-bottom:5px;
}

/* log */

.myTrips-dialog-log-wrapper {
  max-width:650px;
  height:100%;
  overflow-y: scroll;
  padding:4px;
  border: 1px solid #ccc;
}

.myTrips-dialog-log-table {
  padding:2px;
  font-size:14px;

}

.myTrips-dialog-log-header {
  font-size:16px;
  font-weight: bold;;
}

.myTrips-dialog-log-entry {
  display:flex;
  font-size:14px;
}

.myTrips-dialog-log-entry-date {
  padding-top:2px;
  flex:1;
}

.myTrips-dialog-log-entry-text {
  padding-top:2px;
  flex:3;
}


.myTrips-dialog-log-entry div img {
  display:inline-block;
  height:25px;
  width:50px;
  margin-top:-2px;
}


.myTrips-search-field {
  margin:5px;
}

.search_input{
  border:1px solid #ccc;
  font-size:14px;
  margin-right:20px;
  padding:4px;
  border-radius: 30px;
  height:34px;
  min-width:250px;
  padding-left:20px;
}

.search-indicator-box {
  display:inline-block;
  margin-left:6px;
}