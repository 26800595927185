.leaflet-div-icon {
	background: transparent;
	border: none;
}

.leaflet-marker-icon .number{
	position: relative;
	top: -38px;
	font-size: 12px;
	width: 25px;
    font-weight: bold;
	text-align: center;
    z-index: 1;
    left:11px;
}