.navbar {
  &.navbar-1 {
    @apply bg-white border-b border-grey-200;
    .navbar-search {
      @apply w-full;
      max-width: calc(0.25rem * 72);
      input {
        @apply appearance-none h-10 w-full rounded-full text-sm bg-grey-100;
        &:focus {
          @apply outline-none;
        }
      }
      button {
        @apply absolute top-0 mt-3;
      }
      input {
        @apply pl-10 pr-5;
      }
      button {
        @apply left-0 ml-4;
      }
    }
  }
  height: 50px;
}
