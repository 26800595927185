[data-logo="white"] {
  .logo {
    @apply bg-white text-grey-900;
    code,
    pre {
      @apply bg-grey-100;
    }
  }
}

[data-logo="grey-100"] {
  .logo {
    @apply bg-grey-100 text-grey-900;
    code,
    pre {
      @apply bg-grey-200;
    }
  }
}

[data-logo="grey-800"] {
  .logo {
    @apply bg-grey-800 text-white;
    code,
    pre {
      @apply bg-grey-700;
    }
  }
}

[data-logo="grey-900"] {
  .logo {
    @apply bg-grey-900 text-white;
    code,
    pre {
      @apply bg-grey-800;
    }
  }
}

[data-logo="indigo-800"] {
  .logo {
    @apply bg-indigo-800 text-white;
    code,
    pre {
      @apply bg-indigo-700;
    }
  }
}

[data-logo="indigo-700"] {
  .logo {
    @apply bg-indigo-700 text-white;
    code,
    pre {
      @apply bg-indigo-600;
    }
  }
}
/*
@import "../variables";

@each $key, $value in $palettes {
  [data-logo="#{$key}"] {
    .logo {
      @apply #{map-get($value, "background-color")};
      @apply #{map-get($value, "text-color")};
      svg {
          @apply #{map-get($value, "logo-icon-color")};
      }
    }
  }
}
*/
